.header {
  background-color: #ffffff;
  color: white;
  padding: 10px;
  border: solid rgb(0, 0, 0, 0.1); /* Add border */
  border-width: 0px 0px 1px 0px;

  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1); /* Add box shadow on the right side */
}
.header .header-content {
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header .header-content .header-right-content {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.header .name {
  text-align: end;
  color: black;
  text-transform: capitalize;
}

.header .avatar-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  cursor: pointer;
}

.logout-btn {
  margin: 10px;
  padding: 10px;
  width: 130px;
  border-radius: 10px;
  border: 0px;
  font-size: 15px;
  font-weight: bold;
  background-color: var(--primary-color);
  color: var(--white-color);
  cursor: pointer;
}
