.sidebar {
    height: 100vh;
    background: #FFDC39 !important;
}

.logo {
    width: 5rem;
    height: 5rem;

}

.menu-container {
    overflow-y: auto;
}

/* Style the sidebar */
.sidebar {
    flex: 0 0 auto;
    /* Fixed width */
    width: 300px !important;
    /* Example width */
    background-color: #ffffff;
    /* Example background color */
    color: #ffffff;
    /* Example text color */
    border: 1px solid #e0e0e0;
    /* Add border */
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    /* Add box shadow on the right side */
}

/* Style the content */
.content {
    flex: 1 1 auto;
    overflow-y: auto;
    /* Allow vertical scrolling */
}

.sidebar .Sidebar-menu {
    max-height: 80vh;
    overflow-y: scroll;
}

.sidebar .Sidebar-menu {
    /* Enable scrollbar */
    overflow-y: scroll;
}

.sidebar .Sidebar-menu::-webkit-scrollbar {
    width: 4px;
    /* Adjust the width of the scrollbar */
}

.sidebar .Sidebar-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
}

.sidebar .Sidebar-menu::-webkit-scrollbar-thumb {
    background: #888;
    /* Thumb color */
    border-radius: 4px;
    /* Optional: Round the corners of the thumb */
}

.sidebar .Sidebar-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Thumb color when hovered */
}

/* Style the footer */
.footer {
    flex: 0 0 auto;
    /* Fixed height */
    background-color: #001529;
    /* Example background color */
    color: #ffffff;
    /* Example text color */
}



/* App.css */

/* Style the sidebar */
.underline {
    flex: 0 0 auto;
    /* Fixed width */
    color: #ffffff;
    /* Example text color */
    border-bottom: 1px solid #ccc;
    /* Add border bottom */
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    /* Add box shadow on the right side */
}

.ant-menu {
    background: #FFDC39 !important;
}

.ant-menu-item {
    color: black !important;
    /* Set menu item color to black */
    height: 30px !important;
}

.ant-menu-item-active:active {
    color: white !important;
    /* Set menu item color to white when active */
}

.ant-menu-item-active:hover {
    color: black !important;
    /* Set menu item color to white when active */
}

.top-logo-menu {
    display: flex;
    flex-direction: row;
    margin: 10px;
    gap: 5px;
}

.para-text {
    color: black;
    line-height: 15px;
}

.xtra {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.display {
    display: none;
}

/* .logout-btn-container {
    background-color: gray;
    position: absolute;
    width: 100%;
    bottom: 0;
  } */
/* .logout-btn {
    margin: 10px;
    padding: 10px;
    width: 130px;
    border-radius: 10px;
    border: 0px;
    font-size: 15px;
    font-weight: bold;
  } */

.ant-layout {
    background: #ffffff;
}

.layout-content-section::-webkit-scrollbar {
    display: none;
}

.ant-menu-dark .ant-menu-item-selected {
    background-color: var(--primary-color);
    color: #ffffff !important;
}